<template>
    <v-card>
        <s-toolbar label="Subir Archivos" dark color="#8e8f91"></s-toolbar>
        <v-container>
            <v-row>
                <v-col cols="12" md="4">
                    <v-file-input
                        label="Anexo"
                        v-model="file"
                        hide-details
                    />
                    <!-- <SignaturePad @save-signature="handleSignature" /> -->
                </v-col>
                <v-col cols="12" md="4">
                    <s-text v-model="nameFile" label="Nombre del Archivo" placeholder="Prueba"></s-text>
                </v-col>
                <v-col cols="12" md="4">
                    <s-text v-model="genParameter" label="Nombre del Parametro (Esto traera la Ruta)" placeholder="routePrueba"></s-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn color="primary" block @click="upload()">Subir</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <AlertSunshine :dialog="dialog" />
    </v-card>
</template>

<script>
import GenUploadFiles from '../../services/General/GenUploadFiles';

export default {
    data(){
        return {
            file : null,
            nameFile : "",
            genParameter : "",
            dialog: false
        }
    },
    methods:{
        handleSignature(image) {
            console.log("Imagen de la firma:", image);
            this.file = image;
        },
        upload() {
            this.dialog = true;

            GenUploadFiles.upload(this.file, this.nameFile, this.genParameter, this.$fun.getUserID())
            .then(() => {
                this.$fun.alert("Archivo Guardado Correctamente", "success");
                this.dialog = false;
            })
            .catch((error) => {
                if (error.response && error.response.status === 409 && error.response.data.needsConfirmation) {
                    this.$fun.alert("El archivo con el mismo nombre ya existe. ¿Quieres reemplazarlo?", "question").then((val) => {
                        if (val.value) {
                            GenUploadFiles.upload(this.file, this.nameFile, this.genParameter, this.$fun.getUserID(), true)
                            .then(() => {
                                this.$fun.alert("Archivo reemplazado correctamente", "success");
                            })
                            .catch((err) => {
                                this.$fun.alert("Error al reemplazar el archivo", "warning");
                                console.error("Error al reemplazar el archivo", err);
                            })
                            .finally(() => {
                                this.dialog = false;
                            });
                        }else{
                            this.dialog = false;
                        }
                    });
                } else {
                    this.$fun.alert("Error al subir el archivo", "warning");
                    console.error("Error al subir el archivo", error);
                    this.dialog = false;
                }
            });
        }
    }
}
</script>